html, body, #root{
  width:100%;
  height: 100%;
}

#root > .container {
  height:100%;
}

.intro-header{
  max-width:700px;
}

#root .ag-theme-alpine {
  --ag-grid-size: 6px;
}